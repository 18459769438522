<template>
  <div class="Accountsecurity">
    <div class="property_first">
      <h2 class="property_header" v-if="userInfo">
        帐号安全<span class="name">（UID: {{ userInfo.userName }}）</span>
      </h2>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="40%">
      <div class="updatebox">
        <p><span>*</span> 原密码</p>
        <el-input v-model="oldpassword" placeholder="请输入内容"></el-input>
      </div>
      <div class="updatebox">
        <p><span>*</span> 新密码</p>
        <el-input v-model="newpassword" placeholder="请输入内容"></el-input>
      </div>
      <div class="updatebox">
        <p><span>*</span> 确认新密码</p>
        <el-input v-model="newpasswordtwo" placeholder="请输入内容"></el-input>
      </div>
      <div class="buttons">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <ul v-if="userInfo">
      <li class="accout-body-item">
        <span class="accout-body-item-left"> 登录密码 </span>
        <p>
          <span class="accout-error">存在风险，请设置密码</span>
          <a href="javascript:;" class="" @click="dialogVisible = true"
            >修改密码</a
          >
        </p>
      </li>
      <li class="accout-body-item">
        <span class="accout-body-item-left">三方绑定</span>
        <p>
          <span>微信</span>
          <a href="javascript:;" class="">绑定/解绑</a>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "Accountsecurity",
  data() {
    return {
      dialogTitle: "",
      dialogVisible: false,
      currenTab: "property",
      newpassword: "",
      newpasswordtwo: "",
      oldpassword: "",
    };
  },
  methods: {
    changeInfo() {},
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
};
</script>

<style lang="less" scoped>
.Accountsecurity {
  padding: 10px 15px;
  ul {
    list-style-type: none;

    li {
      list-style-type: none;
      margin-left: -46px;
    }
  }
  .el-dialog {
    border-radius: 10px;
    .el-dialog__body {
      padding-top: 10px;
    }
    .updatebox {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      p {
        width: 50%;
        line-height: 40px;
      }
      span {
        color: red;
      }
    }
    .buttons {
      margin-top: 39px;
      display: flex;
      justify-content: flex-end;
      .el-button--default {
        background: #ececec;
        color: #7d7f85;
        border-radius: 10px;
        margin-right: 15px;
      }
      .el-button--primary {
        background: #074ad1;
        border-radius: 10px;
      }
    }
  }
  .property_first {
    display: flex;
    justify-content: space-between;
  }
  .property_header {
    height: 48px;
    line-height: 48px;
    font-size: 18px;
    font-weight: 600;
    color: #2e2e2e;
    padding-left: 10px;
    .name {
      color: rgb(141, 158, 167);
      font-size: 0.875rem;
      font-weight: 400;
      user-select: text;
    }
  }
  .loginout {
    color: #ff5837;
    font-size: 14px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .accout-body-item {
    width: 40%;
    color: #646b71;
    font-weight: 400;
    font-size: 14px;
    margin-right: 20px;
    .accout-body-item-left {
      width: 56px;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      padding-left: 16px;
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
    }
    p {
      margin-top: 20px;
      padding-left: 16px;
      width: calc(100% - 72px);
      color: #222226;
      a {
        display: inline-block;
        min-width: 56px;
        margin-left: 32px;
        color: #1989fa;
      }
    }
    &:first-child {
      border-top: none;
    }
  }

  .form {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    .left {
      display: flex;
      .title {
        width: 100px;
      }
    }
    h3 {
      margin: 1rem 0px 1rem;
      font-size: 1rem;
      font-weight: 500;
      color: rgb(65, 80, 88);
    }
    .desc {
      margin: 7px 0px 24px;
      color: rgb(125, 139, 148);
      font-size: 14px;
    }
    .accountWx {
      width: 135px;
      .nickname {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
</style>